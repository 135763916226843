const { createGlobalStyle } = require('styled-components');

const Typography = createGlobalStyle`

html{
font-family: Roboto, sans-serif;
}

nav > ul> a > li { 
  font-family: Roboto, sans-serif;
  font-weight: 600;
  box-shadow: none;
}

p, li{  
  font-family: Lora;
}

h1,h2,h3,h4,h5,h6{
  font-family: Roboto, sans-serif;
  font-weight: normal;
  margin: 0;
}

a,
a:link,
a:visited,
a:hover,
a:active{
    text-decoration: none;
    color: var(--white);
    
}


.center{
text-align: center;
}
`;

export default Typography;
