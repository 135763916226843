import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Footer from './Footer';
import Nav from './Nav';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const Content = styled.div``;
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#1E8BF2',
      main: '#155fcc',
      dark: '#155fcc',
      contrastText: '#000',
    },
    secondary: {
      light: '#F7842B',
      main: '#f7842b',
      dark: '#C65700',
      contrastText: '#000',
    },
  },
});
const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Typography />
    <ThemeProvider theme={theme}>
      <div>
        <Nav />

        <Content>
          {children}
          <Footer />
        </Content>
      </div>
    </ThemeProvider>
  </>
);

export default Layout;
