const { createGlobalStyle } = require('styled-components');

const GlobalStyles = createGlobalStyle`

:root {
  --blue: #155fcc;
  --darkBlue: #092753;
  --orange: #f7842b;
  --grey: #303030;
  --white: #FFFFFF;
  --black: #040e16;
  --primaryBackground: linear-gradient(
    180deg,
    rgba(21, 95, 204, 1) 0%,
    rgba(9, 39, 83, 1) 100%
  );
  --secondaryBackground: linear-gradient(
    180deg,
    rgba(247, 132, 43, 1) 0%,
    rgba(247, 168, 43, 1) 100%
  );
}

html{
  font-size: 10px;
  /* width: 100%;  */
}

body{
  font-size: 2rem;  
}

.bottomRightShadow
{
  /* width:350px;height:200px; */
  border: solid 1px #555;
  background-color: #eed;
  box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
  -moz-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
  -o-box-shadow: 10px 10px  5px rgba(0,0,0,0.6);
  border-radius:10px;
}

.curvedImage
{
  border-radius:10px 10px 0px 0px;
}
.centerItem{
  display: flex;
  justify-content: center;
  align-items: center;
}

`;

export default GlobalStyles;
