import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { SiInstagram } from 'react-icons/si';

const SiInstagramStyled = styled(SiInstagram)`
  padding-left: 10px;
  font-size: 3rem;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(48, 48, 48);
  background: linear-gradient(
    180deg,
    rgba(48, 48, 48, 0.8673844537815126) 0%,
    rgba(48, 48, 48, 1) 100%
  );
  border-top: 1px solid var(--black);
  color: var(--white);
`;

const FooterItem = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
`;

const Copyright = styled.div`
  padding-bottom: 4px;
  font-size: 1.5rem;
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query NavSettingsQuery {
      settings: allSanitySiteSettings {
        nodes {
          logoAlternative {
            asset {
              fixed(width: 160) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  `);

  const siteSettings = data.settings.nodes[0];

  return (
    <FooterContainer>
      <FooterItem>
        <Img fixed={siteSettings.logoAlternative.asset.fixed} alt="logo" />
      </FooterItem>

      <FooterItem>
        Follow us
        <a href="https://www.instagram.com/utahhoopsclub/">
          <SiInstagramStyled color="var(--white)" />
        </a>
      </FooterItem>

      <FooterItem>
        <Copyright>
          © {new Date().getFullYear()} Utahhoops.club, All rights reserved.
        </Copyright>
      </FooterItem>
    </FooterContainer>
  );
};

export default Footer;
