import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { FiInstagram } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';

import Drawer from '@material-ui/core/Drawer';

const GiHamburgerMenuStyled = styled(GiHamburgerMenu)`
  display: flex;
  color: var(--white);
  font-size: 4rem;
  height: auto;
  padding-left: 20px;
  /* flex-direction: row-reverse; */
  @media (min-width: 600px) {
    display: none;
  }
`;

const StyledUl = styled.ul`
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const StyledLi = styled.li`
  display: flex;
  text-decoration: none;
  box-shadow: none;
  text-transform: uppercase;
  color: white;
  padding-right: 4rem;
`;

const Navbar = styled.nav`
  display: flex;
  /* flex-wrap: wrap; */
  max-height: 62px;
  background-color: var(--blue);

  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  font-size: 1.8rem;

  Img {
    /* TODO can I figure out how to get the logo back on the left in mobile? */
    /* display: none; */
  }

  @media (min-width: 600px) {
    /* display: ; */
  }
`;

const DrawerContainer = styled.div`
  display: flex;
  /* background-color: var(--blue); */
  flex-direction: column;
`;

const DrawerHeader = styled.div`
  font-size: 3rem;
  justify-content: flex-end;
  text-align: right;
  padding: 20px;
  border-bottom: 1px solid var(--grey);
`;
const DrawerItem = styled.div`
  padding: 20px 50px 20px 100px;
  color: var(--black);
  font-size: 3rem;
  text-transform: uppercase;
`;

const navLinks = [
  // { title: `home`, path: `/` },
  // { title: `about us`, path: `/about-us` },
  // { title: `blog`, path: `/blog` },
  // { title: `contact`, path: `/contact` },
  // { title: `faq`, path: `/faq` },
];

export default function Nav() {
  const [navDisplayed, setNavDisplayed] = useState(false);

  const data = useStaticQuery(graphql`
    query FooterSettingsQuery {
      settings: allSanitySiteSettings {
        nodes {
          logoAlternative {
            asset {
              fixed(width: 80) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  `);

  const siteSettings = data.settings.nodes[0];

  const handleMenuOpen = () => {
    setNavDisplayed(true);
  };

  const handleMenuClose = () => {
    setNavDisplayed(false);
  };

  return (
    <Navbar>
      <div>
        <Img fixed={siteSettings.logoAlternative.asset.fixed} alt="logo" />
      </div>
      <div>
        <GiHamburgerMenuStyled onClick={handleMenuOpen} />
        <Drawer
          anchor="right"
          open={navDisplayed}
          onClose={navDisplayed}
          variant="persistent"
        >
          <DrawerContainer>
            <DrawerHeader>
              <GrClose onClick={handleMenuClose} />
            </DrawerHeader>

            {/* <ul> */}
            {navLinks.map(({ title, path }) => {
              console.log(title);
              return (
                // <li>{title}</li>
                <Link to={path} key={title}>
                  {/* <li>{title}</li> */}
                  <DrawerItem onClick={handleMenuClose}>{title}</DrawerItem>
                </Link>
              );
            })}
            {/* </ul> */}
          </DrawerContainer>
        </Drawer>
        <StyledUl>
          {navLinks.map(({ title, path }) => (
            <Link to={path} key={title}>
              <StyledLi>{title}</StyledLi>
            </Link>
          ))}

          {/* Add External Links */}
          <StyledLi>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/utahhoopsclub/"
            >
              <FiInstagram color="var(--white)" />
            </a>
          </StyledLi>
        </StyledUl>
      </div>
    </Navbar>
  );
}
